import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../../components/layout'
import { Container, Alert} from 'reactstrap'
import EnquiryForm from "../../containers/enquiry"
import { isLoggedIn } from '../../services/auth'
import { navigate } from '@reach/router'
import { Banner } from '../../components/banner';

export default class enquiry extends Component {
    constructor(props){
        super(props)

        this.state = {
            visible : !this.props.location.state ? false : true,
            notificationMessage : !this.props.location.state ? "" : props.location.state.notificationMessage,
        }

        this.onDismiss = this.onDismiss.bind(this)

    }

    async componentDidMount(){
        if(!isLoggedIn()){
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
        }
        this.forceUpdate()
    }

    UNSAFE_componentWillUpdate(){
        if(!isLoggedIn()){
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
        }
    }


    onDismiss(){
        this.setState({
            visible : !this.state.visible
        })
    }

  render() {
    if(!isLoggedIn()){
        return ''
    } else {
        return (
            <Layout>
                <Banner title={`Enquiry`} />
                <Container
                style={{
                    padding: '2rem 2rem 1rem',
                    minHeight: '75vh'
                }}
                >
                <Alert color="success" toggle={this.onDismiss} isOpen={this.state.visible} >
                    {this.state.notificationMessage}
                </Alert>
    
                    <EnquiryForm />
    
                </Container>
            </Layout>
        )
      }
    }
}
