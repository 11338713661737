import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { Row, Col, Form, FormGroup, Label, Input, Button, FormFeedback, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'

import formStyles from '../components/styles/form.module.css'
import { handleEnquiry, handleGetEnquiries } from '../services/api';
import { isLoggedIn } from "../services/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { PulseLoader } from 'react-spinners';
import validator from 'validator'
import { navigate } from '@reach/router';


export default class EnquiryForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            enquiry: null,
            enquiryDefIndex: 0,
            description: "",
            sendError: false,
            sendMessage: "",
            isLoading: false,
            captcha: "",
            recaptcha: true,

            descriptionValid: false,
            validateForm: false,

            modal: false,
            enquiries: []
        };
        this.modal = this.modal.bind(this)

    }

    async componentDidMount () {
        if (!isLoggedIn) {
            navigate('/')
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        } else {
            const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

            let enquiriesData = await handleGetEnquiries()
            this.setState({
                name: user.userData.attributes.name,
                email: user.userData.attributes.email,
                enquiries: enquiriesData,
                enquiry: enquiriesData[this.state.enquiryDefIndex]
            })
        }

    }




    modal () {
        this.setState({
            modal: !this.state.modal
        })
    }

    captchaChange = (value) => {
        const token = value

        this.setState({
            captcha: token
        }, () => {
            this.validateForm()
        })
    }

    validateInput (field, value) {
        let descriptionValid = this.state.descriptionValid

        switch (field) {
            case 'description':
                descriptionValid = !validator.isEmpty(value)
                break;

            default:
                break;
        }
        this.setState({
            descriptionValid: descriptionValid
        }, () => {
            this.validateForm()
        })
    }

    validateForm () {
        let validateForm = this.state.descriptionValid && !!this.state.captcha
        this.setState({
            validateForm: validateForm
        })
    }

    handleTypeChange = event => {
        const name = event.target.id;
        const value = this.state.enquiries[event.target.value];
        this.setState({
            [name]: value,
            enquiryDefIndex: event.target.value
        });
    }

    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, () => {
            this.validateInput(name, value)
        });
    }

    handleSubmit = async event => {
        event.preventDefault()
        console.log(this.state)
        this.setState({
            isLoading: true
        }, async () => {
            await handleEnquiry(this.state)
                .then((res) => {
                    if (res) {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.modal()
                        })
                    } else {
                        this.setState({
                            sendError: true,
                            sendMessage: "An error has been encountered",
                            isLoading: false
                        })
                    }

                })
                .catch((err) => {
                    console.log(err)
                    this.setState({
                        sendError: true,
                        sendMessage: err.message,
                        isLoading: false
                    })
                })
        })
    }

    renderEnquiryOptions () {
        return this.state.enquiries.map(function (value, index) {
            return (
                <option value={index} key={index}>{value.type}</option>
            )
        });
    }

    render () {
        return (
            <Row className={[formStyles.formContainer, "row"].join(' ')} >


                <Col sm={{
                    size: 12
                }}
                    md={{
                        size: 10,
                        offset: 1
                    }}
                >
                    <h3 style={{
                        fontWeight: 'bolder'
                    }}>
                        Send us an enquiry on any of our APIs and our consultants will get back to you.
                </h3>

                    <Form
                        onSubmit={this.handleSubmit}
                    >
                        <FormGroup>
                            <Label for="email">Enquiry Type* :</Label>
                            <Input
                                id="enquiry"
                                autoFocus
                                type="select"
                                name="enquiry"
                                value={this.state.enquiryDefIndex}
                                onChange={this.handleTypeChange}
                                className={formStyles.signUp}
                                required
                            >
                                {this.renderEnquiryOptions()}

                            </Input>

                        </FormGroup>

                        <FormGroup>
                            <Label for="description">Description* :</Label>
                            <Input
                                id="description"
                                autoFocus
                                type="textarea"
                                name="description"
                                placeholder="Please fill in this field"
                                value={this.state.description.value}
                                onChange={this.handleChange}
                                className={formStyles.signUp}
                                required
                            />
                        </FormGroup>
                        <FormGroup >
                            <FormFeedback invalid={!this.state.sendError ? '' : undefined}>
                                {this.state.sendMessage}
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <ReCAPTCHA
                                sitekey={process.env.CAPTCHA_SITEKEY}
                                onChange={this.captchaChange}
                                ref={(r) => this.recaptcha = r}
                            />
                        </FormGroup>

                        <Button
                            color={this.state.isLoading || !this.state.validateForm ? 'secondary' : 'primary'}
                            disabled={this.state.isLoading || !this.state.validateForm}
                        >
                            {this.state.isLoading ? <PulseLoader color="white" /> : "Submit"}
                        </Button>

                        <Modal
                            isOpen={this.state.modal}
                            toggle={this.modal}
                            className={this.props.className}


                            onClosed={() => {
                                navigate('/support/enquiry/', { state: { visible: true, notificationMessage: "Enquiry has been submitted successfully" } })
                                if (typeof window !== "undefined") {
                                    window.location.reload()
                                }
                            }}
                        >
                            <ModalHeader toggle={this.modal}>Success!</ModalHeader>
                            <ModalBody>
                                Dear {this.state.name}<br />
                            Thank you for your submission. One of our staff members will contact you by email as soon as possible.
                        </ModalBody>
                            <ModalFooter>.
                            <Button
                                    color="success"
                                    onClick={() => {
                                        this.modal()
                                        navigate('/support/enquiry/', { state: { visible: true, notificationMessage: "Enquiry has been submitted successfully" } })
                                        if (typeof window !== "undefined") {
                                            window.location.reload()
                                        }
                                    }}
                                >
                                    Continue
                            </Button>{' '}
                            </ModalFooter>
                        </Modal>
                    </Form>
                </Col>
            </Row>
        )
    }
}
